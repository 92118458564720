@font-face {
  font-family: Syne;
  src: url(Syne-ExtraBold.ttf);
}
@font-face {
  font-family: SyneLight;
  src: url(Syne-SemiBold.ttf);
}

body {
  color: #ccff40;
  font-family: Syne;
  text-shadow: 1px 1px 1px #2600ad;
  background-color: #454545;
}

p {
  font-family: SyneLight;
}

:link,
:visited {
  color: #b7acfa;
}
